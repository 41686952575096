.tournament .inner-container {
  flex: 1;
  position: relative;
  display: flex;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.tournament .inline-results {
  margin: 0 auto;
  padding-top: 5vh;
}

#pipeClickPowered-custom-id {
  display: none !important;
}

.branding .content .countdown {
  display: block;
  flex: 1;
  font-size: 5rem;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  text-align: center;
}

.chat-list .video-conference {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.chat-list .video-embed {
  height: 40vh;
  border: 0;
}

.chat-list .video-conference svg {
  height: 2em;
  width: 2em;
}

/* .width: "30vw", height: "16.875vw" */
.rce-smsg.video {
  /* width: 20vw;
  height: 16.875vw; */
  margin: 2vw;
  margin-top: 0;
}

.tournament-results-container {
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; */
  margin: 0 auto;
  flex-direction: column;
  justify-content: flex-start;
}

.tournament-results-container h4 {
  text-align: center;
}

.tournament-results-container h3 {
  margin: 3rem 0;
}

.tournament-results-container .results {
  max-width: 1200px;
  height: 100%;
  margin: 0;
  width: 100%;
  overflow-y: auto;
}

.tournament-results-container .results .result {
  margin: 1rem 0;
}

.tournament-results-container .results .result > div {
  display: flex;
  flex-direction: row;
  padding: 1rem 0;
  align-items: center;
  justify-content: stretch;
}

.tournament-results-container .results .result > div > div {
  flex: 1;
  padding: 0 1rem;
  overflow: hidden;
}
.tournament-results-container .results .result > div > div:first-child {
  width: 4rem;
  flex: none;
  text-align: center;
}
.tournament-results-container .results .result div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.tournament-results-container .results .result > div > div:nth-child(2) {
  width: 6rem;
  flex: none;
  text-align: center;
}
.tournament-results-container .results .result > div > div:nth-child(3) {
  overflow: hidden;
}

.tournament-results-container .results .result > div > div:nth-child(3) > h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.tournament-results-container .results .result > div > div:nth-child(4) {
  width: 14rem;
  flex: none;
  text-align: center;
}

.tournament-results-container .results .result > div > div:nth-child(5) {
  flex: none;
  text-align: center;
  width: 8rem;
}

.tournament .loading {
  flex: 1;
  align-self: center;
  text-align: center;
  font-size: 4rem;
}

.tournament .waiting-message {
  grid-area: notification;
  /* position: absolute; */
  /* margin-top: -5rem;
  top: 50%;
  left: 25%;
  right: 25%; */
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  /* border-radius: 1rem; */
  display: none;
}

.tournament .pause-message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  /* #a8326b */
  background-color: rgba(168, 50, 78, 1);
  text-align: center;
  z-index: 101;
}

.tournament .pause-message.pending {
  background-color: rgba(0, 0, 0, 0.8);
  height: 3rem;
  font-size: 1.5rem;
}

.tournament .room-overlay {
  display: grid;
  grid-template-columns: minmax(640px, 1fr) 1fr;
  grid-gap: 24px;
  width: 100%;
  padding: 24px 24px 0;
}

.tournament .room-overlay.zoom .branding {
  height: 100%;
  justify-content: flex-start;
  max-height: 100px;
}

.tournament .room-overlay.zoom .branding .content {
  background-position-x: center;
  background-position-y: top;
}

.tournament .room-overlay .room {
  flex: 1;
}

.tournament .chat-list,
.chat-side-container .chat-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  color: black;
  font-size: 1.5rem;
  overflow: hidden;
  /* grid-area: chat; */
  padding: 1vw;
  padding-top: 0;
}

.tournament .chat-list > .message-list,
.chat-side-container .chat-list > .message-list {
  flex: 1;
  position: relative;
  min-height: 10vh;
}

.tournament .chat-list .rce-container-input {
  border-radius: 10px;
  padding: 3px;
  overflow: hidden;
}

.tournament .chat-list > .message-list > div,
.chat-side-container .chat-list > .message-list > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.tournament .player-grid-tournament {
  padding: 0 0 24px;
  overflow-y: auto;
  margin-top: 24px;
}

.tournament .table-list {
  overflow-y: auto;
  overflow-x: hidden;
}

.tournament .player-grid-tournament div.missing {
  opacity: 0.45;
}

.tournament .player-grid-tournament div.arrived {
  opacity: 1;
}

.tournament .player-grid-tournament > div > div {
  position: relative;
}

.tournament .player-grid-tournament .player-index {
  position: absolute;
  top: 5%;
  right: 5%;
  font-size: 1rem;
  font-weight: 600;
}

.tournament .player-grid-tournament .player-has-video {
  position: absolute;
  top: 5%;
  left: 5%;
}

.tournament .player-grid-tournament .player-has-video > svg {
  font-size: 2rem;
  font-weight: 600;
}

.tournament .action-button {
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}

.tournament .copy-link {
  display: inline-flex;
  align-items: center;
}

.tournament .copy-link img {
  height: 2rem;
  margin-left: 1rem;
  vertical-align: middle;
}

.tournament .copy-link span {
  color: white;
  display: inline-block;
  text-transform: uppercase;
  max-width: 6rem;
  line-height: 1.4rem;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}

.tournament .player-grid-tournament > div {
  background-size: cover;
  background-position: center;
  display: inline-flex;
  align-items: stretch;
  justify-content: stretch;
  width: 10vw;
  height: 10vw;
  padding: 1px;
  margin: 10px;
  max-width: 260px;
}

.tournament .player-grid-tournament > div > div {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  padding: 1rem;
}

.tournament .player-grid-tournament > div > div > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tournament-right-drawer {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.tournament-right-drawer .MuiTabPanel-root {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px 0 0 0;
}

.tournament-right-drawer .MuiTabPanel-root[hidden] {
  visibility: hidden;
  flex: 0;
  padding: 0;
}

.results-side-container {
  padding-top: 12px;
}

.chat-side-container {
  display: flex;
  flex: 1;
}

.rce-smsg.video {
  height: 100%;
  padding: 0;
}

.rce-smsg-video {
  display: block;
  height: 100%;
  margin-bottom: -0.65rem;
}

.rce-smsg.video .rce-smsg-text {
  padding: 0.25rem;
}

.chat-list .rce-smsg-video video {
  max-height: 20vh;
}

.tournament .header-message {
  color: #fff;
  flex: 1;
  text-align: center;
  font-weight: 600;
  width: 60%;
  text-align: center;
  background-color: rgba(168, 50, 78, 0.95);
  height: 3rem;
  padding: 0.5rem;
  border: 1px solid white;
  z-index: 99;
  font-size: 1.1rem;
}

.tournament .header-message.bottom {
  position: fixed;
  bottom: 0;
  right: 5%;
  border-top-right-radius: 1.5rem;
  border-top-left-radius: 1.5rem;
  border-bottom-width: 0;
}

.tournament .header-message.top {
  position: absolute;
  top: 0;
  left: 33%;
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  border-top-width: 0;
}

.tournament .system-message {
  position: absolute;
  z-index: 99;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
}

.tournament .system-message > div {
  color: #fff;
  flex: 1;
  text-align: center;
  font-weight: 600;
  height: 8rem;
  line-height: 8rem;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 4rem;
  border: 1px solid white;
  z-index: 99;
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  display: inline-flex;

  padding: 0 5vw;
  margin: 0 25%;
}

.tournament .system-message > div > span {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
}

@media screen and (orientation: portrait) {
  /* .tournament .copy-link {
    left: unset;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding: 0.5em;
    font-size: 2rem;
  } */
  .tournament .waiting-message {
    font-size: 2rem;
    height: 4rem;
  }
  .tournament .pause-message {
    margin-top: 0;
    font-size: 2rem;
    top: 16px;
    left: 20%;
    right: 16px;
    height: 56px;
  }

  .tournament .room-overlay {
    /* display: flex; */
    display: grid;
    grid-template-areas:
      "branding"
      "video-zone"
      "chat"
      "players"
      "button";
    grid-template-rows: auto auto 1fr 20vh auto auto;
    grid-template-columns: 1fr;
  }

  .tournament .player-grid-tournament {
    padding-left: 0;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
  }

  .tournament .player-grid-tournament > div {
    /* 17x11 */
    width: 24vh;
    height: 24vh;
    padding: 1px;
  }
}

@media (min-width: 960px) {
  .tournament .header-message {
    width: 30%;
  }
}

@media (min-width: 1280px) {
  .tournament .player-grid-tournament > div {
    min-width: none;
  }
}
