.card {
  height: 160px;
  width: 120px;
  /* transition: width 2s, height 2s, transform 2s;
  transform: translateX(100px); */
  border-radius: 0.5rem;
}

.card.inactive {
  opacity: 0.15;
}

.card.active {
  opacity: 1 !important;
}

.card > img {
  width: auto;
  height: 100%;
  max-height: 100%;
}

.flipper-tile {
  position: relative;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-perspective: 1000;
  -ms-transform: perspective(1000px);
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  perspective: 1000;
}
.flipper-tilewrap {
  position: relative;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -moz-transition: 0.6s;
  -moz-transform: perspective(1000px);
  -moz-transform-style: preserve-3d;
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  transition: 0.6s;
  transition-property: transform;
  transform-style: preserve-3d;
  display: flex;
  /* overflow: hidden; */
}
.flipper-tilefront,
.flipper-tileback {
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  -webkit-transform: rotateY(0deg);
  -moz-transition: 0.6s;
  -moz-transform-style: preserve-3d;
  -moz-transform: rotateY(0deg);
  -o-transition: 0.6s;
  -o-transform-style: preserve-3d;
  -o-transform: rotateY(0deg);
  -ms-transition: 0.6s;
  -ms-transform-style: preserve-3d;
  -ms-transform: rotateY(0deg);
  transition: 0.6s;
  transition-property: transform;
  transform-style: preserve-3d;
  transform: rotateY(0deg);
  transition-delay: 300ms;
}
.flipper-tilefront {
  -webkit-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
}
.flipper-tileback {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
/* .flipper-tilewrap:hover:not(.istouchdevice) .flipper-tileback,
.flipper-tilewrap.hover.istouchdevice .flipper-tileback {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flipper-tilewrap:hover:not(.istouchdevice) .flipper-tilefront,
.flipper-tilewrap.hover.istouchdevice .flipper-tilefront {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
} */

.flipper-tilewrap.flipped .flipper-tileback {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flipper-tilewrap.flipped .flipper-tilefront {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.flipper-tile.vertical .flipper-tileback {
  -webkit-transform: rotateX(-180deg);
  -moz-transform: rotateX(-180deg);
  -ms-transform: rotateX(-180deg);
  -o-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
}
.flipper-tile.vertical
  .flipper-tilewrap.flipped
  .flipper-tileback {
  -webkit-transform: rotateX(0deg);
  -moz-transform: rotateX(0deg);
  -ms-transform: rotateX(0deg);
  -o-transform: rotateX(0deg);
  transform: rotateX(0deg);
}
.flipper-tile.vertical
  .flipper-tilewrap.flipped
  .flipper-tilefront {
  -webkit-transform: rotateX(180deg);
  -moz-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -o-transform: rotateX(180deg);
  transform: rotateX(180deg);
}
