.chipstack {
  display: flex;
  flex-direction: column;
  /* top: 70px; */
  position: relative;
  /* padding-right: 1rem; */
  /* margin-top: -2rem; */
  z-index: 3;
  /* width: 8rem; */
  /* height: 5rem; */
  justify-content: center;
  /* transition: all 0.5s ease; */
  /* margin-bottom: 1.2rem; */
  align-items: center;
}

.chipstack .rows {
  display: flex;
  flex-direction: row;
  padding-left: 2rem;
}

.chipstack.animated {
  top: 70px;
  position: absolute;
  margin-top: -2rem;
}

.chipstack .row:first-child {
  /* margin-left: -1rem; */
}

.chipstack .row {
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 2.5rem;
  margin-left: -2rem;
}
.chipstack .amount {
  /* position: absolute;
  bottom: -0.75rem;
  left: 0;
  right: 0; */
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  background-color: rgba(0,0,0,0.75);
  border-radius: 1rem;
  color: white;
  padding: 0 1rem;
  white-space: nowrap;
}

.chipstack .pokerchip {
  margin: 0.5rem;
  font-size: 1.2rem;
  position: relative;
  display: inline-block;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 2.5rem;
  height: 2.5rem;
  background: #555;
  border-radius: 50%;
  position: relative;
  border: 0.5rem dashed white;
  transition: all 0.5s ease;
  backface-visibility: hidden;
  margin-bottom: -2.65rem;
}

.chipstack .pokerchip.red {
  background: #dd4631;
}

.chipstack .pokerchip.orange {
  background: #de9324;
}

.chipstack .pokerchip.blue {
  background: #317ddd;
}

.chipstack .pokerchip.green {
  background: #067414d6;
}

.chipstack .pokerchip.black {
  background: #000;
}

.chipstack .pokerchip.flat {
  box-shadow: 0 0 0 0.2rem #fff;
}

.chipstack .pokerchip.iso {
  transform: perspective(3000px) rotateX(55deg) rotateZ(-40deg);
  /* box-shadow: -1px 1px 0px #555, -2px 2px 0px #555, -3px 3px 0px #555, -4px 4px 0px #555, -5px 5px 0px #555, -6px 6px 10px #555; */
  box-shadow: -0.5px 0.5px 0px #555, -1px 1px 0px #555, -1.5px 1.5px 0px #555, -2px 2px 0px #555, -2.5px 2.5px 0px #555, -3px 3px 5px #555;

}

.chipstack .pokerchip.flat:hover {
  transform: rotate(90deg);
}

.chipstack .pokerchip.iso:hover {
  transform: perspective(3000px) rotateX(55deg) rotateZ(-40deg)
    translateZ(0.5rem);
}
