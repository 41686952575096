/* ----------------------------------------------
 * Generated by Animista on 2020-8-4 18:9:0
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.seat {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #000;
  overflow: visible;
  background-size: cover;
  color: #000;
  /* transition: border ease-in-out 0.5s; */
  transition: top, left, right, bottom 1s ease-in-out;
  /* border: 8px solid transparent; */
  border: 2px solid transparent;
}

.seat .last-action {
  display: flex;
  flex-direction: row;
  /* position: absolute; */
  /* margin-top: -2rem; */
  z-index: 3;
  width: 8rem;
  height: 1.5rem;
  line-height: 1.5rem;
  font-weight: bold;
  justify-content: center;
  /* margin-top: 11.5rem; */
  background-color: #caae53;
  border-radius: 1rem;
  color: white;
}

.seat .video-progress {
  background-size: cover;
  border-radius: 50%;
}

.seat .video-progress {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;
}

.seat .actions {
  display: none;
}
/* .seat .video-progress:hover .actions { */
.seat .video-progress:hover .actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  justify-content: center;
  z-index: 102;
}

.seat .video-progress .actions > div {
  background-color: rgb(0, 0, 0, 0.85);
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.seat .video-progress .actions svg {
  font-size: 2rem;
}

.seat .seat-video {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;
}

.seat .seat-video-with-indicator {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 97;
}

.seat .seat-video-with-indicator .audio {
  position: absolute;
  z-index: 97;
  bottom: 0;
  left: -5%;
  width: 1rem;
  height: 1rem;
  padding: 6px;
  box-sizing: content-box;
  background-color: rgb(0, 0, 0, 0.85);
  border-radius: 50%;
}

.seat .seat-video-with-indicator .network {
  position: absolute;
  z-index: 97;
  bottom: 0;
  right: -5%;
  width: 1rem;
  height: 1rem;
  padding: 6px;
  box-sizing: content-box;
  background-color: rgb(0, 0, 0, 0.85);
  border-radius: 50%;
}

.seat .video-progress:hover .audio {
  left: calc(-3rem - 12px);
  width: 2rem;
  height: 2rem;
  padding: 12px;
}
.seat .video-progress:hover .network {
  right: calc(-3rem - 12px);
  width: 2rem;
  height: 2rem;
  padding: 12px;
}

.seat video {
  object-fit: cover !important;
  height: 100%;
}

.seat .profile {
  position: absolute;
  display: flex;
  flex-direction: row;
  height: 2.5rem;
  white-space: nowrap;
  line-height: 2.5rem;
  padding-right: 1rem;
  background-color: rgba(255, 255, 255, 0.75);
  border-top-left-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
  max-width: 10rem;
  z-index: 90;
  min-width: 8rem;
}

.seat .profile > div {
  overflow: hidden;
  text-overflow: ellipsis;
}

.seat .avatar {
  margin-right: 0.5rem;
}

.seat.folded,
.seat.away {
  opacity: 0.25;
  z-index: 1;
}

.seat:hover {
  opacity: 1;
}

.table .seat.small-blind::before,
.table .seat.big-blind::before,
.table .seat.dealer::before {
  content: "D";
  position: absolute;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  left: 50%;
  top: 0;
  margin-top: 0.5rem;
  margin-left: 2.5rem;
  box-shadow: 0px 0px 0.5rem black;
  z-index: 98;
  text-align: center;
  color: #fff;
  font-weight: 900;
  padding: 0.15rem;
  background-color: rgba(0, 141, 230, 1);
  font-size: 180%;
}

.table .seat.dealer::before {
  content: "D";
  background-color: rgba(0, 141, 230, 1);
  font-size: 180%;
}

.table .seat.small-blind::before {
  content: "SB";
  color: rgb(72, 72, 72);
  background-color: rgba(255, 182, 0, 1);
  font-size: 120%;
}

.table .seat.big-blind::before {
  content: "BB";
  color: rgb(72, 72, 72);
  background-color: rgba(255, 182, 0, 1);
  font-size: 120%;
}

.seat .rebuy {
  position: absolute;
  top: 0;
  margin-top: -2rem;
  width: 9rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-color: #fff;
  z-index: 98;
  line-height: 3rem;
  text-align: center;
  font-weight: 900;
}

.seat .stack {
  position: absolute;
  top: 100%;
  width: 70%;
  /* background-color: inherit; */
  left: 15%;
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;
  text-align: center;
  line-height: 1.25rem;
  /* background-color: rgba(255, 255, 255, 0.95); */
  font-weight: 600;
  padding: 0.35rem;
}

.seat .away {
  position: absolute;
  bottom: 100%;
  width: 72%;
  left: 14%;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  text-align: left;
  line-height: 1.25rem;
  background-color: rgba(255, 255, 255, 0.95);
  font-weight: 600;
  /* padding: 0.35rem; */
}

.seat .away button {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
}

.seat .away .MuiButton-containedSizeSmall {
  padding: 4px 0;
  min-width: 5rem;
}

.seat .show {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0%;
  text-align: left;
  line-height: 1.25rem;
  background-color: rgba(255, 255, 255, 0.95);
  font-weight: 600;
  padding: 0.35rem;
  z-index: 102;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr;
}

.seat .show button {
  margin-left: 0;
  margin-right: 0;
  border-radius: 0;
  white-space: nowrap;
}

.seat .show .MuiButton-containedSizeSmall {
  padding: 4px 0;
  min-width: 5rem;
}

.seat.action {
  border: 2px solid #fff;
  /* box-shadow: 0px 0px 12px 12px #a86f32;
	-webkit-animation: heartbeat 3s ease-in-out both;
  animation: heartbeat 3s ease-in-out both; */
  /* font-size: 150%; */
}

.seat .avatar {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.seat .pocket {
  position: absolute;
  /* border: 4px solid navy; */
  transform-origin: 50% 50%;
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 9rem;
}

.seat .pocket .cards {
  display: flex;
  flex-direction: row;
}

.seat .round-contributions {
  position: absolute;
  top: 0;
  margin-top: -2rem;
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
  z-index: 97;
}

.seat .payout {
  position: absolute;
  top: 0;
  /* width: 3rem; */
  height: 2rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  font-weight: bold;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  z-index: 98;
}

.seat .payout.win {
  /* -webkit-animation: glow-small 0.75s linear infinite alternate; */
  -webkit-animation: heartbeat 3s ease-in-out both;
  animation: heartbeat 3s ease-in-out both;
}

.seat .payout.lose {
  opacity: 0.5;
  font-weight: 500;
}

.seat .video-progress:hover {
  width: 200%;
  height: 200%;
  z-index: 103;
}

.seat .pocket-art {
  position: absolute;
  display: flex;
  flex-direction: column;
  min-height: 5rem;
  justify-content: center;
  width: 8rem;
}

.seat .top .pocket-art,
.seat .pocket.portrait_top .pocket-art {
  bottom: unset;
  left: unset;
  top: unset;
  right: unset;
  margin-left: unset;
  margin-bottom: unset;
  margin-right: unset;
  margin-top: unset;
  bottom: 100%;
  z-index: 100;
  left: 50%;
  margin-left: -4rem;
  margin-bottom: -2.5rem;
  justify-content: flex-end;
}

.seat .bottom .pocket-art,
.seat .pocket.portrait_bottom .pocket-art {
  bottom: unset;
  left: unset;
  top: unset;
  right: unset;
  margin-left: unset;
  margin-bottom: unset;
  margin-right: unset;
  margin-top: unset;
  top: 55%;
  z-index: 100;
  left: 120%;
  margin-left: -4rem;
  margin-top: -4rem;
  justify-content: center;
}

.seat .left .pocket-art,
.seat .pocket.portrait_left .pocket-art {
  bottom: unset;
  left: unset;
  top: unset;
  right: 0;
  margin-left: unset;
  margin-bottom: unset;
  z-index: 100;
  margin-right: -5rem;
  margin-top: 0;
  justify-content: center;
  /* justify-content: flex-end; */
}

.seat .right .pocket-art,
.seat .pocket.portrait_right .pocket-art {
  bottom: unset;
  left: 0;
  top: unset;
  right: unset;
  margin-left: unset;
  margin-bottom: unset;
  margin-right: unset;
  margin-top: unset;
  margin-left: -5rem;
  margin-top: 0;
  justify-content: center;
  /* right: 0; */
}

.table .seat .stack {
  /* background-color: #111111; */
  color: #fff;
}

.table .seat .profile {
  background-color: rgb(243, 243, 243);
  font-weight: 600;
  padding-right: 0;
}

.table .seat .profile .avatar {
  display: none;
}

.table .seat .profile .name {
  width: 100%;
  text-align: center;
  margin: 0 0.5rem;
}

/* Disable hover of the current player */
.p1.seat.p1 .video-progress {
  pointer-events: none;
}

@media screen and (orientation: landscape) {
  .seat .pocket .cards > div.card:first-child {
    margin-right: 0;
    z-index: 1;
    box-shadow: -1px 0px 5px 0.5px #444;
  }
  .seat .pocket .cards > div.card:nth-child(2) {
    margin-left: -15%;
    z-index: 2;
    margin-top: 5%;
    box-shadow: -1px 0px 5px 0.5px #444;
  }

  .table .seat.p1 .profile,
  .table .seat.p2 .profile,
  .table .seat.p6 .profile,
  .table .seat.p7 .profile,
  .table .seat.p8 .profile {
    top: 40%;
    z-index: 96;
    margin-left: -1rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    left: 100%;
    margin-right: unset;
    right: unset;
  }

  .table .seat.p5 .profile {
    top: 40%;
    z-index: 96;
    margin-right: -1rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    right: 100%;
    margin-left: unset;
    left: unset;
    padding-left: 0.5rem;
  }
  .table .seat.p5 .profile .name {
    padding-right: 1rem;
    text-align: left;
  }
  .table .seat.p5 .profile .stack {
    right: 0;
    left: unset;
    padding-right: 1rem;
    width: 85%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .table .seat.p1 .profile {
    top: 50%;
    margin-left: -1.25rem;
  }

  .table .seat.p3 .profile,
  .table .seat.p4 .profile {
    margin-top: 0;
    left: unset;
    margin-left: 0%;
    top: 95%;
    z-index: 101;
  }

  .table .seat.p1 .profile .stack,
  .table .seat.p2 .profile .stack,
  .table .seat.p6 .profile .stack,
  .table .seat.p7 .profile .stack,
  .table .seat.p8 .profile .stack {
    left: 0;
    padding-left: 1rem;
    width: 85%;
  }

  .table .seat.p1 .profile .away {
    left: 0;
    width: 85%;
  }

  .table .seat.p1 .profile .away button {
    padding-left: 1rem;
  }

  .table .seat.p1 .profile .name,
  .table .seat.p2 .profile .name,
  .table .seat.p6 .profile .name,
  .table .seat.p7 .profile .name,
  .table .seat.p8 .profile .name {
    padding-left: 1.5rem;
    text-align: left;
  }

  .table .seat.p2,
  .table .seat.p6,
  .table .seat.p8 {
    margin-top: -9.5rem;
  }

  .table .seat.p1,
  .table .seat.p5,
  .table .seat.p7 {
    margin-bottom: -9.5rem;
  }

  .table .seat.p3 {
    margin-left: -10rem;
  }
  .table .seat.p4 {
    margin-right: -10rem;
  }

  .seat .top {
    top: 0;
    left: 50%;
    margin-left: -45%;
    margin-top: -40%;
    width: 100%;
  }

  .seat .top.visible {
    margin-left: -50%;
    margin-top: -85%;
  }

  .seat.p1 .top {
    top: 50%;
    left: -45%;
    margin-top: -35%;
  }

  .seat .bottom {
    top: 100%;
    left: 50%;
    margin-left: -50%;
    margin-top: -35%;
    width: 100%;
  }

  .seat .bottom.visible {
    margin-left: -50%;
    margin-top: -15%;
  }

  .p1.seat .video-progress:hover,
  .p5.seat .video-progress:hover,
  .p7.seat .video-progress:hover {
    margin-top: -100%;
    margin-left: -50%;
  }

  .p2.seat .video-progress:hover,
  .p6.seat .video-progress:hover,
  .p8.seat .video-progress:hover {
    margin-bottom: -100%;
    margin-left: -50%;
  }

  .p4.seat .video-progress:hover {
    margin-top: -50%;
    margin-left: -100%;
  }

  .p10.seat .video-progress:hover {
    margin-top: -50%;
    margin-left: -100%;
  }

  .p3.seat .video-progress:hover {
    margin-top: -50%;
    margin-right: -50%;
  }

  .p9.seat .video-progress:hover {
    margin-top: -50%;
    margin-right: -50%;
  }

  .seat.p1 .pocket-art {
    margin-bottom: 0rem;
  }

  .seat.p1 .last-action {
    display: none;
  }

  .seat .bottom .round-contributions,
  .seat .bottom .rebuy {
    top: 100%;
    z-index: 100;
    left: 50%;
    margin-left: -1.5rem;
    margin-top: -1rem;
  }

  .seat .left .round-contributions,
  .seat .left .rebuy {
    top: unset;
    right: 0;
    z-index: 100;
    margin-right: -3rem;
    margin-top: 0;
  }

  .seat .left .rebuy {
    margin-right: -9rem;
  }

  .seat .right .round-contributions,
  .seat .right .rebuy {
    top: unset;
    left: 0;
    z-index: 100;
    margin-left: -3rem;
    margin-top: 0;
  }

  .seat .right .chipstack {
    margin-top: 0;
  }
  .seat .right .last-action {
    z-index: 100;
  }

  .seat .right .rebuy {
    margin-left: -10rem;
  }

  .seat .top .payout {
    margin-top: -1.5rem;
  }

  .seat.p1 .top .payout {
    margin-top: -2rem;
  }

  .seat .bottom .payout {
    top: unset;
    bottom: 0;
    margin-bottom: calc(-1.5rem - 5%);
    margin-top: unset;
  }

  .seat .left .payout {
    top: 50%;
    left: 105%;
    margin-top: -1rem;
  }

  .seat .right .payout {
    top: 50%;
    right: 105%;
    margin-top: -1rem;
  }

  .seat .left {
    left: 5%;
    margin-left: 60%;
    width: 100%;
    margin-top: 0;
  }

  .seat .left.visible {
    margin-left: 90%;
    margin-top: 0;
  }

  .seat .right {
    right: 5%;
    margin-right: 60%;
    width: 100%;
    margin-top: 0;
  }

  .seat .right.visible {
    margin-right: 90%;
    margin-top: 0;
  }

  .seat .top_left {
    right: 0;
    top: 0;
    margin-right: 85%;
    margin-top: -10%;
    width: 100%;
  }
  .seat .top_right {
    left: 0;
    top: 0;
    margin-left: 85%;
    margin-top: -10%;
    width: 100%;
  }
  .seat .bottom_left {
    right: 0;
    top: 0;
    margin-right: 85%;
    margin-top: 50%;
    width: 100%;
  }
  .seat .bottom_right {
    left: 0;
    bottom: 0;
    margin-left: 85%;
    margin-bottom: -10%;
    width: 100%;
  }

  /* card rotations */

  .table .seat.p2 .video-progress,
  .table .seat.p6 .video-progress,
  .table .seat.p8 .video-progress {
    transform: rotateZ(0);
  }

  .table .seat.p2 .pocket .cards,
  .table .seat.p6 .pocket .cards,
  .table .seat.p8 .pocket .cards {
    transform: rotateZ(180deg);
    backface-visibility: hidden;
  }

  .table .seat.p5 .pocket {
    left: 75%;
    margin-top: -40%;
  }
  .table .seat.p5 .pocket .card:first-child {
    transform: rotateZ(15deg);
  }
  .table .seat.p5 .pocket .card:nth-child(2) {
    transform: rotateZ(25deg);
  }

  .table .seat.p1 .top {
    margin-top: -25%;
  }

  .table .seat.p8 .pocket {
    margin-top: -40%;
    left: 75%;
  }
  .table .seat.p8 .pocket .card:first-child {
    transform: rotateZ(-25deg);
    margin-top: 10%;
  }
  .table .seat.p8 .pocket .card:nth-child(2) {
    transform: rotateZ(-15deg);
    margin-top: 0;
  }

  .table .seat.p6 .pocket {
    margin-top: -35%;
    left: 25%;
  }
  .table .seat.p6 .pocket .card:first-child {
    transform: rotateZ(15deg);
    margin-top: -5%;
  }
  .table .seat.p6 .pocket .card:nth-child(2) {
    transform: rotateZ(25deg);
    margin-top: 5%;
  }

  .table .seat.p2 .pocket {
    margin-top: -40%;
  }
  .table .seat.p2 .pocket .card:first-child {
    transform: rotateZ(-10deg);
    margin-top: 0;
  }
  .table .seat.p2 .pocket .card:nth-child(2) {
    transform: rotateZ(-3deg);
    margin-top: 0;
  }

  .table .seat.p7 .pocket {
    left: 25%;
    margin-top: -35%;
  }
  .table .seat.p7 .pocket .card:first-child {
    transform: rotateZ(-25deg);
    margin-top: 20%;
  }
  .table .seat.p7 .pocket .card:nth-child(2) {
    transform: rotateZ(-15deg);
  }

  .table .seat.p1 .pocket .card:first-child {
    transform: rotateZ(-10deg);
  }
  .table .seat.p1 .pocket .card:nth-child(2) {
    transform: rotateZ(-3deg);
  }

  .table .seat.p3 .pocket {
    left: 100%;
    margin-left: -45%;
  }
  .table .seat.p3 .pocket .cards {
    transform: rotateZ(90deg);
    backface-visibility: hidden;
  }
  .table .seat.p3 .pocket .card:first-child {
    transform: rotateZ(-10deg);
  }
  .table .seat.p3 .pocket .card:nth-child(2) {
    transform: rotateZ(-3deg);
  }

  .table .seat.p4 .pocket {
    right: 0;
    margin-right: 55%;
  }
  .table .seat.p4 .pocket .cards {
    transform: rotateZ(-90deg);
    backface-visibility: hidden;
  }

  .table .seat.p4 .pocket .card:first-child {
    transform: rotateZ(-10deg);
  }
  .table .seat.p4 .pocket .card:nth-child(2) {
    transform: rotateZ(-3deg);
  }
  .table .seat.p2 .pocket.visible,
  .table .seat.p6 .pocket.visible,
  .table .seat.p8 .pocket.visible {
    margin-top: -15%;
  }

  .table .seat.p2 .pocket.visible .card > div,
  .table .seat.p6 .pocket.visible .card > div,
  .table .seat.p8 .pocket.visible .card > div {
    transform: rotateZ(180deg);
    backface-visibility: hidden;
  }
}

.table .seat.p2 .pocket.visible .card:first-child,
.table .seat.p6 .pocket.visible .card:first-child,
.table .seat.p8 .pocket.visible .card:first-child {
  z-index: 101;
}

@media screen and (orientation: portrait) {
  .seat .profile {
    width: 10rem;
  }

  .seat .portrait_top {
    top: 0;
    left: 50%;
    margin-left: -50%;
    margin-top: -80%;
    width: 100%;
  }

  .seat .portrait_top.visible {
    margin-top: -80%;
  }

  .seat .portrait_bottom {
    top: 55%;
    left: 155%;
    margin-left: -50%;
    margin-top: -40%;
    width: 100%;
  }

  .seat .pocket.portrait_bottom .pocket-art {
    top: 100%;
    left: 50%;
  }

  .seat .portrait_bottom.visible {
    margin-top: -20%;
  }

  .seat .portrait_left {
    left: 0;
    margin-left: 65%;
    width: 100%;
  }

  .seat .portrait_left.visible {
    margin-left: 90%;
  }

  .seat .portrait_right {
    right: 0;
    margin-right: 65%;
    width: 100%;
  }

  .seat .portrait_right.visible {
    margin-right: 90%;
  }

  .seat .portrait_top_left {
    right: 0;
    top: 0;
    margin-right: 85%;
    margin-top: -10%;
    width: 100%;
  }
  .seat .portrait_top_right {
    left: 0;
    top: 0;
    margin-left: 85%;
    margin-top: -10%;
    width: 100%;
  }
  .seat .portrait_bottom_left {
    right: 0;
    top: 0;
    margin-right: 85%;
    margin-top: 50%;
    width: 100%;
  }
  .seat .portrait_bottom_right {
    left: 0;
    bottom: 0;
    margin-left: 85%;
    margin-bottom: -10%;
    width: 100%;
  }

  .seat.p3 .profile,
  .seat.p5 .profile,
  .seat.p8 .profile,
  .seat.p6 .profile,
  .seat.p4 .profile,
  .seat.p9 .profile,
  .seat.p10 .profile,
  .seat.p7 .profile {
    bottom: 100%;
    left: 50%;
    margin-left: -5rem;
    margin-bottom: 2rem;
  }

  .seat.p1 .profile,
  .seat.p2 .profile {
    left: 100%;
    top: 50%;
    margin-top: -20px;
    margin-left: 1rem;
  }

  .seat.p2 .profile {
    top: 25%;
  }

  .seat.p11 .profile {
    bottom: 100%;
    left: 0%;
    margin-left: 0%;
    margin-bottom: 2rem;
  }

  .seat.p12 .profile {
    bottom: 75%;
    left: 0%;
    margin-left: 95%;
    margin-bottom: 2rem;
  }

  .seat .pocket .cards > div.card:first-child {
    margin-right: 0;
    z-index: 1;
    box-shadow: -1px 0px 5px 0.5px #444;
  }
  .seat .pocket .cards > div.card:nth-child(2) {
    margin-left: -15%;
    z-index: 2;
    margin-top: 5%;
    box-shadow: -1px 0px 5px 0.5px #444;
  }

  .p1.seat .video-progress:hover {
    margin-top: -100%;
    margin-left: -50%;
  }

  .p2.seat .video-progress:hover {
    margin-bottom: -100%;
    margin-left: -50%;
  }

  .p4.seat .video-progress:hover,
  .p6.seat .video-progress:hover,
  .p7.seat .video-progress:hover {
    margin-top: -50%;
    margin-left: -100%;
  }

  .p3.seat .video-progress:hover,
  .p5.seat .video-progress:hover,
  .p8.seat .video-progress:hover {
    margin-top: -50%;
    margin-right: -50%;
  }

  .seat .bottom .round-contributions,
  .seat .bottom .rebuy {
    top: 100%;
    z-index: 100;
    margin-top: -1rem;
  }

  .seat .portrait_right .payout,
  .seat .portrait_left .payout,
  .seat .portrait_bottom .payout {
    top: unset;
    bottom: 0;
    margin-bottom: calc(-1rem - 5%);
    margin-top: unset;
  }

  .seat.dealer::before {
    top: unset;
    bottom: 0;
    margin-top: unset;
    margin-bottom: -1rem;
  }

  .seat .rebuy::before {
    top: unset;
    bottom: 0;
    margin-top: unset;
    margin-bottom: -1rem;
  }

  .seat.p1 .last-action {
    display: none;
  }

  .seat.p1 .portrait_top {
    top: 50%;
    margin-left: unset;
    left: -80%;
    margin-right: -35%;
    margin-top: -50%;
  }
  .seat .pocket.portrait_top .pocket-art {
    margin-bottom: 0;
  }
}

.seat .progress {
  width: 100%;
  height: 100%;
  transform: scale3d(1.2, 1.2, 1);
  transform-origin: center;
  position: absolute;
  z-index: 102;
}
