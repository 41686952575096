.registration {
  display: flex;
  flex-wrap: wrap;
  flex: 1
}

.registration .hero {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.registration .hero > img {
  max-width: 20vw;
  max-height: 20vh;
}

.registration h3 {
  text-align: center;
}

.registration .complete {
  text-align: center;
  font-size: 150%;
}

.registration .complete .code {
  border: 1px solid white;
}

.registration .tournamentContainer .tournamentTable {
  width: calc(50vw - 10px);
  height: calc(50vh - 40px);
  /* max-width: 600px; */
  /* max-height: 400px; */
  position: relative;
  margin: 5px;
  background-color: black;
  overflow-y: hidden;
}

.registration .tournamentContainer .tournamentTable.active {
  position: absolute;
  width: unset;
  height: unset;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  padding: 3vw;
}

.registration .tournamentContainer .tournamentTable.active:before {
  content: unset!important;
}

.registration .tournamentContainer .tournamentTable.active > div {
  zoom: 1;
  height: unset!important;
}

.registration .tournamentContainer .tournamentTable:before {
  content:'';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.registration .tournamentContainer .tournamentTable:hover:before {
  content: 'Observe Table';
  background-color: rgba(255,255,255,0.75);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 500%;
  color: #000;
  cursor: pointer;
}

.registration .tournamentContainer .tournamentTable > div {
  height: 75vh!important;
  zoom: 60%;
}

.registration .tournamentContainer .tournamentTable .table .options {
  position: absolute;
}

.registration .playerlist {
  display: flex;
  flex-direction: column;
  padding: 2vh 4vw;
}

.registration .registrants {
  display: flex;
  flex-direction: column;
}

.registration .registrants > div {
  display: flex;
  flex: 1;
}

.registration .registrants .unenrolled {
  font-weight: bold;
}

.registration .registrants .joined {
  font-style: italic;
}

.registration .registrants > div .name {
  flex: 1;
  font-size: 1rem;
}

.registration .registrants > div .created,
.registration .registrants > div .code {
  width: 10vw;
  font-size: 2rem;
  text-align: right;
  align-self: center;
}

.registration .registrants > div .created {
  font-size: 1rem;
}